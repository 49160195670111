
export class Convocatoria{

  id: string;
  dateRange: string;
  status: string;

  constructor() {
    this.id = undefined;
    this.dateRange = undefined;
    this.status = undefined;
  }

 }
