import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';

import { MatTableModule} from '@angular/material/table';
import { MatPaginatorModule} from '@angular/material/paginator';

import { MatTabsModule} from '@angular/material/tabs';
import { OdooCallComponent } from './odooCall/odoo-call/odoo-call.component';
import { FormInscripcionComponent } from './odooCall/form-inscripcion/form-inscripcion.component';
import { FormExitoComponent } from './odooCall/form-exito/form-exito.component';

import { RouterLinkDelayModule } from '@bcodes/ngx-routerlink-delay';

import { ServicesService } from './services/services.service';
import { HttpClientModule } from '@angular/common/http';
import { ApiService } from './services/api.service';


@NgModule({
  declarations: [
    AppComponent,
    OdooCallComponent,
    FormInscripcionComponent,
    FormExitoComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatTableModule,
    MatPaginatorModule,
    BrowserAnimationsModule,
    MatTabsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterLinkDelayModule,
    HttpClientModule
  ],
  providers: [
    ServicesService,
    ApiService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
