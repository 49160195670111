import { Component, OnDestroy } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { formatDate } from '@angular/common';
import { Curso } from '../../models/curso';
import { Cursos } from '../../models/cursos.model';
import { Convocatoria } from '../../models/convocatoria.model';
import { ServicesService } from '../../services/services.service' ;
import { ApiService } from 'src/app/services/api.service';
import { Subscription } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-odoo-call',
  templateUrl: './odoo-call.component.html',
  styleUrls: ['./odoo-call.component.css'],
})
export class OdooCallComponent implements OnDestroy{

  public loading: boolean;
  today= new Date();
  jstoday = '';
  jstoyear = '';

  // Variables para guardar resultados de Call por años
  resultCall: any[];
  callConvoActual: any[];
  convoList: any[];
  curso: Array<Curso>;
  seleccionId: any[];
  callCourseView: any[];

  // Variables para la sumas de precios del programa completo para usuarios socio y no socios.
  sumNo: number;
  sumSo: number;
  fileJson: any;
  year: any;
  disabled: boolean;
  vistaFinal: any[];
  tableCursoConvoList: Cursos[];
  getCoursesSub: Subscription;

  loadDataError: boolean = false

  constructor(public service: ServicesService, private apiService: ApiService) {
    this.jstoday = formatDate(this.today, 'yyyy-mm-dd', 'en-US');
    this.tableCursoConvoList=[];
    this.loading = true;
    this.disabled = false;
    this.curso = [];
    this.seleccionId = [];
    this.callCourseView = [];
    this.resultCall = [];
    this.callConvoActual = [];
    this.convoList = [];
    this.sumNo = 0;
    this.sumSo = 0;
    // Variables para fechas
    var options = { month: 'long', day: 'numeric' };

    this.getCertificateCourses()
    this.service.coursesList = []; }
  //** Fin constructor **/

  // Inicializamos el registro local para poder usar el PIPE decimal en los precios de la tabla
  ngOnInit(){
    registerLocaleData(localeEs, 'es');
  }

  ngOnDestroy(): void {
    this.getCoursesSub.unsubscribe()
  }

  // Funcion para verificar checkbox seleccionados , recoger sus values y añadirlos al array anterior
  updateChecked(value,event){
    if(event.target.checked){
      this.service.coursesList.push(value);
    }
    else if (!event.target.checked){
      let indexx = this.service.coursesList.indexOf(value);
      this.service.coursesList.splice(indexx,1);
    }
  }

  // Funcion para cambiar el formato de la fecha y nos la muestre con dia(numerico) y mes(string)
  formatDate(date: any, days: number) {
    var month = new Array();
      month[0] = "Enero";
      month[1] = "Febrero";
      month[2] = "Marzo";
      month[3] = "Abril";
      month[4] = "Mayo";
      month[5] = "Junio";
      month[6] = "Julio";
      month[7] = "Agosto";
      month[8] = "Septiembre";
      month[9] = "Octubre";
      month[10] = "Noviembre";
      month[11] = "Diciembre";

    let dateIni = new Date();
    let dateInicio = dateIni;

    dateIni.setFullYear(date.toString().slice(0,4));
    dateIni.setDate(date.toString().slice(8,10));
    dateIni.setMonth(date.toString().slice(5,7)-1);


    // Si el numero de dias del curso es 1, convertimos la fecha de esta forma para que nos muestre el dia
    if(days === 1 ) {

      return dateIni.getDate() + ' ' + month[dateIni.getMonth()];

      // Si el numero de dias que se imparte el curso es mayor de 1, convertimos la fecha para que nos muestre ambos dias con el formato elegido.
    } else if (days > 1){

      let dateFin = new Date(dateInicio.setDate(dateInicio.getDate()));

      let dateFinal = new Date(dateFin.setDate(dateFin.getDate() + days-1))

      // Si el primer dia de curso coincide con final de mes, se aplica esta metodo para que el segundo dia sea el primero del mes siguiente.
      if(dateIni.getMonth() === dateFin.getMonth()){

        return dateIni.getDate() + ' y ' + dateFinal.getDate() + ' ' + month[dateFinal.getMonth()];

      } else{

        return dateIni.getDate() + ' ' + month[dateIni.getMonth()] + ' y ' + dateFinal.getDate() + ' ' + month[dateFinal.getMonth()];

      }
    } else {

      return '';

    }
  }

  // Funcion para aplicar un texto dependiendo el numero de plazas libres u ocupadas
  numeroPlazas(plazas: number , listaEspera: number , sizeLista: number , date: any) {

    // Obtenemos la fecha de hoy
    var hoy = new Date();
    var dd = hoy.getDate();
    var mm = hoy.getMonth()+1;
    var yyyy = hoy.getFullYear();

    // Dos digitos para cada parte de la fecha
    function addZero(i) {
      if (i < 10) {
          i = '0' + i;
      }
      return i;
    }

    dd=addZero(dd);
    mm=addZero(mm);

    // Formateamos la fecha
    var fecha = yyyy+'-'+mm+'-'+dd;

    if (fecha >= date) {
      return "Vencido";
    } else if (plazas <= 10 && plazas >= 5) {
      return "Pocas plazas";
    } else if(plazas === 0 && listaEspera < sizeLista) {
      return "Lista de espera";
    } else if(plazas === 0 && listaEspera >= sizeLista){
      return "Completo";
    } else if(plazas < 5) {
      return "Ultimas Plazas";
    } else{
      return '';
    }
  }

  checkboxDisabled(event,id, item) {
    if(event.target.checked){
      item.convocatorias.forEach(convo => {
        if(convo.id !== id){
          $("#convo_"+convo.id).prop("disabled", true);
        }
      });
    } else{
      item.convocatorias.forEach(convo => {
        if(convo.status!== 'Completo' && convo.status!== 'Vencido'){
          $("#convo_"+convo.id).prop("disabled", false);
        }else{
          $("#convo_"+convo.id).prop("disabled", true);
        }
      });
    }
  }

//-----------------------------------------------------------------------------------------------------------------------------------------------------------------------------

  //*** API ***//
  getCertificateCourses(){
    this.getCoursesSub = this.apiService.getCertificateCourses()
        .subscribe({
          next: (res) => {
            if (res) {

              if(res.data.resultCode === '200'){
                this.resultCall = res.data.resultCall
                this.convoList = res.data.tableCursoConvoList
                this.getTableDates(this.convoList)

              }else{
                this.loadDataError = true
                console.log('Error:', res.data.resultCode)
              }

            } else {
              this.loadDataError = true
              console.log('Error: res null data')
            }
          },
          error: (error) => {
            this.loadDataError = true
            console.log('Error:', error)
          },
        });
  }

  getTableDates(convoList: any[]){
    this.tableCursoConvoList = [];
    convoList.forEach(curs => {
      curs.forEach(convo => {
        let curso = this.tableCursoConvoList.find(c => c.name === convo.dato.name);
        if (curso === undefined){
          curso = new Cursos();
          curso.name = convo.dato.name;
          curso.numDias = convo.dato.length;
          const convocatoria = new Convocatoria();
          convocatoria.id = convo.dato.id;
          convocatoria.dateRange = this.formatDate(convo.dato.date, convo.dato.length);
          convocatoria.status = this.numeroPlazas(convo.dato.availablePlaces, convo.dato.waitingList, convo.dato.waitingListSize, convo.dato.date);
          curso.convocatorias.push(convocatoria);
          this.tableCursoConvoList.push(curso);
        } else {
          const convocatoria = new Convocatoria();
          convocatoria.id = convo.dato.id;
          convocatoria.dateRange = this.formatDate(convo.dato.date, convo.dato.length);
          convocatoria.status = this.numeroPlazas(convo.dato.availablePlaces, convo.dato.waitingList, convo.dato.waitingListSize, convo.dato.date);
          curso.convocatorias.push(convocatoria);
          if(curso.numDias !== convo.dato.length){
            curso.numDias = convo.dato.length
          }
        }
      });
    });
  }
}
