import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


const baseUrl = environment.baseUrl;
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }

  getCertificateCourses(): Observable<any> {
    const url = baseUrl + 'certificateCourses'
    let headers = new HttpHeaders();
    return this.http.get<any>(url, { headers: headers });
  }

  saveCertificateRegistration(body: any): Observable<any> {
    const url = baseUrl + 'certificateRegistration'
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post<any>(url, body, { headers: headers });
  }

}
