import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router} from '@angular/router';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { dniValidator } from '../form-inscripcion/dni';
import { checkNIF } from '../form-inscripcion/nif';
import { ServicesService } from '../../services/services.service';
import {formatDate, SlicePipe } from '@angular/common';

import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-form-inscripcion',
  templateUrl: './form-inscripcion.component.html',
  styleUrls: ['./form-inscripcion.component.css']
})

export class FormInscripcionComponent implements OnInit, OnDestroy{

  subscriptionValueChanges: Subscription;
  saveRegistrationSub: Subscription;
  resPartnerId: any;
  icaStudentId: any;
  studentResPatnerId: any;
  date: any;
  submitted = false;
  // Variable para formulario
  user: FormGroup;
  // Variable para los checkbox de curso Completo y curso Suelto
  cursoCompleto = false;
  cursoSuelto = false;

  // Variable para recoger los datos del formulario
  sendingData: boolean;
  desactivado: boolean;

  emailPattern: any = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  provincias = [
    {id  : '318', name  :  'A Coruña'},{id  : '267', name  :  'Álava'},{id  : '757', name  :  'Albacete'},{id  : '269', name  :  'Alicante'},{id  : '759', name  :  'Almería'},{id  : '760', name  :  'Asturias'},{id  : '761', name  :  'Ávila'},
    {id  : '762', name  :  'Badajoz'},{id  : '274', name  :  'Baleares'},{id  : '764', name  :  'Barcelona'},{id  : '765', name  :  'Burgos'},{id  : '766', name  :  'Cáceres'},{id  : '767', name  :  'Cádiz'},{id  : '768', name  :  'Cantabria'},
    {id  : '280', name  :  'Castellón'},{id  : '770', name  :  'Ceuta'},{id  : '771', name  :  'Ciudad Real'},{id  : '772', name  :  'Córdoba'},{id  : '773', name  :  'Cuenca'},{id  : '774', name  :  'Gerona'},{id  : '775', name  :  'Granada'},
    {id  : '776', name  :  'Guadalajara'},{id  : '288', name  :  'Guipúzcoa'},{id  : '778', name  :  'Huelva'},{id  : '779', name  :  'Huesca'},{id  : '780', name  :  'Jaén'},{id  : '781', name  :  'La Rioja'},{id  : '782', name  :  'Las Palmas'},
    {id  : '783', name  :  'León'},{id  : '784', name  :  'Lérida'},{id  : '785', name  :  'Lugo'},{id  : '786', name  :  'Madrid'},{id  : '787', name  :  'Málaga'},{id  : '788', name  :  'Melilla'},{id  : '789', name  :  'Murcia'},
    {id  : '790', name  :  'Navarra'},{id  : '791', name  :  'Orense'},{id  : '792', name  :  'Palencia'},{id  : '793', name  :  'Pontevedra'},{id  : '794', name  :  'Salamanca'},{id  : '795', name  :  'Santa Cruz de Tenerife'},
    {id  : '796', name  :  'Segovia'},{id  : '797', name  :  'Sevilla'},{id  : '798', name  :  'Soria'},{id  : '799', name  :  'Tarragona'},{id  : '800', name  :  'Teruel'},{id  : '801', name  :  'Toledo'},
    {id  : '802', name  :  'Valencia'},{id  : '803', name  :  'Valladolid'},{id  : '315', name  :  'Vizcaya'},{id  : '805', name  :  'Zamora'},{id  : '806', name  :  'Zaragoza'}]


  constructor( private rutaActiva: ActivatedRoute, public fb: FormBuilder, private  router: Router, protected service: ServicesService, private apiService: ApiService) {

    this.subscriptionValueChanges = new Subscription;
    this.saveRegistrationSub = new Subscription;

    if(!this.service.coursesList){
      this.router.navigate(['/']);
    }

    this.resPartnerId = null;
    this.icaStudentId = null;
    this.studentResPatnerId = null;
    this.sendingData = false;
    this.desactivado = false;
    // Si los datos recogidos en la tabla son 5, el programa completo sera True
    if (this.service.coursesList.length === 6) {
      this.cursoCompleto = true;
    } else {
      this.cursoSuelto = true;
    }

    this.date = formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss', 'en-US','UTC');

    // Control de campos para el formulario
    this.user = new FormGroup({
      // Parte izq del formulario, inscripcion de alumno
      apellidos: new FormControl('',[Validators.required,Validators.minLength(3)]),
      nombre: new FormControl('',[Validators.required, Validators.minLength(3)]),
      cargo: new FormControl(''),
      telefono: new FormControl('',[Validators.required,Validators.minLength(9)]),
      email: new FormControl('',[Validators.required,Validators.pattern(this.emailPattern)]),
      empresa: new FormControl('',[Validators.required]),
      sector: new FormControl(''),
      nif: new FormControl('',[Validators.required, Validators.minLength(9), dniValidator]),
      cp: new FormControl('',[Validators.required,Validators.minLength(5)]),
      poblacion: new FormControl('',[Validators.required, Validators.minLength(2)]),
      direccion: new FormControl('',[Validators.required]),
      provincia: new FormControl('',[Validators.required]),
      web: new FormControl(''),
      completo: new FormControl({value: this.cursoCompleto, disabled: true}),
      suelto: new FormControl({value: this.cursoSuelto, disabled: true}),
      empresaOrUser: new FormControl('',[Validators.required]),
      socio: new FormControl('',[Validators.required]),

      // Parte derecha del formulario, datos de facturacion
      nomEmpresa: new FormControl(''),
      nombreFac: new FormControl(''),
      apellidoFac: new FormControl(''),
      direccionFac: new FormControl(''),
      cpFac: new FormControl(''),
      poblacionFac: new FormControl(''),
      provinciaFac: new FormControl(''),
      nifFac: new FormControl(''),
      telefonoFac: new FormControl('',[Validators.minLength(9)]),

      // Politicas, condiciones,RGPD y boletin informativo
      rgpd: new FormControl('',[Validators.requiredTrue]),
      boletin: new FormControl(''),
    });

  }

  ngOnInit() {
    this.setFacturaEmpresaValidators();
  }

  ngOnDestroy() {
    this.subscriptionValueChanges.unsubscribe();
    this.saveRegistrationSub.unsubscribe();
    this.service.coursesList = null;
  }

  // Metodo para la validacion segun se elija asistente o empresa en el metodo de facturacion
  setFacturaEmpresaValidators() {
    const nif = this.user.get('nifFac');
    const poblacion = this.user.get( 'poblacionFac');
    const provincia = this.user.get('provinciaFac');
    const cp = this.user.get('cpFac');
    const direccion = this.user.get('direccionFac');
    const nomEmpresa = this.user.get('nomEmpresa');
    const nombreFac = this.user.get('nombreFac');
    const apellidoFac = this.user.get('apellidoFac');

    this.subscriptionValueChanges = this.user.get('empresaOrUser').valueChanges.subscribe(facturaCategory => {
      if (facturaCategory === 'FALSE') {
        nif.setValidators(null);
        poblacion.setValidators(null);
        provincia.setValidators(null);
        cp.setValidators(null);
        direccion.setValidators(null);
        nomEmpresa.setValidators(null);
        nombreFac.setValidators(null);
        apellidoFac.setValidators(null);
      }
      if (facturaCategory === 'TRUE') {
        nif.setValidators([checkNIF,Validators.required]);
        poblacion.setValidators([Validators.required, Validators.minLength(2)]);
        provincia.setValidators([Validators.required]);
        cp.setValidators([Validators.required, Validators.minLength(5)]);
        direccion.setValidators([Validators.required, Validators.minLength(5)]);
        nomEmpresa.setValidators([Validators.required, Validators.minLength(3)]);
        nombreFac.setValidators([Validators.required, Validators.minLength(3)]);
        apellidoFac.setValidators([Validators.required, Validators.minLength(3)]);
      }
      nif.updateValueAndValidity();
      poblacion.updateValueAndValidity();
      provincia.updateValueAndValidity();
      cp.updateValueAndValidity();
      direccion.updateValueAndValidity();
      nomEmpresa.updateValueAndValidity();
      nombreFac.updateValueAndValidity();
      apellidoFac.updateValueAndValidity();
    });
  }

  get empresa() { return this.user.get('empresa');}
  get nombre() { return this.user.get('nombre');}
  get apellidos() { return this.user.get('apellidos');}
  get telefono() { return this.user.get('telefono');}
  get email() { return this.user.get('email');}
  get nif() { return this.user.get('nif');}
  get cp() { return this.user.get('cp');}
  get rgpd() { return this.user.get('rgpd');}
  get boletin() { return this.user.get('boletin');}
  get empresaOrUser() { return this.user.get('empresaOrUser');}
  get socio() { return this.user.get('socio');}
  get direccion() { return this.user.get('direccion');}
  get poblacion() { return this.user.get('poblacion');}
  get provincia() { return this.user.get('provincia');}
  get direccionFac() { return this.user.get('direccionFac');}
  get provinciaFac() { return this.user.get('provinciaFac');}
  get cpFac() { return this.user.get('cpFac');}
  get nifFac() { return this.user.get('nifFac');}
  get poblacionFac() { return this.user.get('poblacionFac');}
  get nomEmpresa() { return this.user.get('nomEmpresa');}
  get nombreFac() { return this.user.get('nombreFac');}
  get apellidoFac() { return this.user.get('apellidoFac');}

  controlChangeDataFact(){
    var nomFact = this.user.value.nombreFac;
    var apellidoFact = this.user.value.apellidoFac;
    if((nomFact && nomFact !== '') || (apellidoFact && apellidoFact !== '')){
      this.user.controls['nomEmpresa'].disable();
    }else{
      this.user.controls['nomEmpresa'].enable();
    }
  }

  controlChangeNomEmp(){
    var nomEmp = this.user.value.nomEmpresa;
    if(nomEmp && nomEmp !== ''){
      this.user.controls['apellidoFac'].disable();
      this.user.controls['nombreFac'].disable();
    }else{
      this.user.controls['apellidoFac'].enable();
      this.user.controls['nombreFac'].enable();
    }
  }

  onSaveForm(){
    this.sendDesactivate();
    if(!this.user.valid){
      // Si el form no es valido nos retorna los Validadores
      this.submitted = true;
      // this.disabled = false;
      window.alert('Formulario incorrecto, rellene los campos obligatorios');
      this.sendActivate();
      return false;

    }else{
      this.saveCertificateRegistration(this.user.value)
    }
  }

  // Funcion para reinicio del Formulario
  onResetForm(){
    this.user.reset();
    // Añadimos la ruta a la web de registro correcto
    this.router.navigate(['/registro']);
  }

  sendActivate(){
    this.sendingData = false;
    this.desactivado = false;
  }

  sendDesactivate(){
    this.sendingData = true;
    this.desactivado = true;
  }


  // ***** API *****//

  saveCertificateRegistration(dataRegistration: any){
    let dataSend = {
      courses: this.service.coursesList,
      dataRegistration: dataRegistration,
      complete : this.cursoCompleto
    }
    this.saveRegistrationSub = this.apiService.saveCertificateRegistration(dataSend)
    .subscribe({
      next: (res) => {
        if (res) {
          if(res.data.resultCode === '200'){
            this.onResetForm()
          }else if(res.data.resultCode === '209' || res.data.resultCode === '210'){
            window.alert(res.data.resultErrorText);
          }else{
            console.log('Error:', res.data.resultCode)
          }
        } else {
          console.log('Error: res null data')
        }
        this.sendActivate()
      },
      error: (error) => {
        console.log('Error:', error)
        this.sendActivate()
      },
    });
  }
}
