import {Convocatoria} from './convocatoria.model';
export class Cursos{
  name: string;
  convocatorias: Convocatoria[];
  numDias: string;
  nonPartnerSinglePrice: number;
  partnerSinglePrice: number;
  partnerProgramPrice: number;
  nonPartnerProgramPrice: number;
  constructor() {
    this.name = undefined;
    this.convocatorias = [];
    this.numDias = undefined;
    this.nonPartnerSinglePrice = undefined;
    this.partnerSinglePrice = undefined;
    this.partnerProgramPrice = undefined;
    this.nonPartnerProgramPrice = undefined;
  }

 }
