
import {AbstractControl} from '@angular/forms'

export function dniValidator(dni: AbstractControl) {
    var validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
    var nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;
    var nieRexp = /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;
    var str = '';
    // Para evitar que el validador nos siga funcionando despues de resetear el formulario, añadimos este if para decirle que solo se aplique si no llega null or undefined
    if(dni.value != undefined){
        str = dni.value.toString().toUpperCase();
    }


    if (!nifRexp.test(str) && !nieRexp.test(str)) {
        return { dniValid: true };
    }

    var nie = str
        .replace(/^[X]/, '0')
        .replace(/^[Y]/, '1')
        .replace(/^[Z]/, '2');

    var letter = str.substr(-1);
    var charIndex = parseInt(nie.substr(0, 8)) % 23;

    if (validChars.charAt(charIndex) === letter) {
        return null;

    }

    return { dniValid: true };
}
