import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {OdooCallComponent} from './odooCall/odoo-call/odoo-call.component';
import {FormInscripcionComponent} from './odooCall/form-inscripcion/form-inscripcion.component';
import { FormExitoComponent } from './odooCall/form-exito/form-exito.component';


const APP_ROUTES: Routes = [
  {path: '', component: OdooCallComponent},
  {path: 'inicio', component: OdooCallComponent},
  {path: 'form', component: FormInscripcionComponent},
  {path: 'registro', component: FormExitoComponent},
];

@NgModule({
  // scrollPositionRestoration: 'enabled' Nos situa al comienzo de la pagina cuando realizamos routerlink
  imports: [RouterModule.forRoot(APP_ROUTES, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
